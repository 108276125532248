<template functional>
  <div :id="data.id" :class="['accordion',  { 'is-shapeless': props.shapeless }, data.class, data.staticClass]" :style="data.style" v-bind="data.attrs" role="tablist">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'Accordion',
  props: {
    shapeless: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
.accordion {}
</style>
