<template functional>
  <div :id="data.id" :class="['backlink', data.class, data.staticClass]" :style="data.style" v-bind="data.attrs">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'Backlink'
}
</script>

<style lang="scss">
// mobile
$backlink-padding-y: $component-padding-y !default;
$backlink-padding-x: 0 !default;
$backlink-font-size: $font-size-sm !default;
$backlink-color: $gray-700 !default;
$backlink-hover-color: darken($backlink-color, 5.1%) !default;

// desktop
$backlink-breakpoint: $desktop-breakpoint !default;

$backlink-desktop-padding-y: $component-desktop-padding-y * 0.5 !default;
$backlink-desktop-padding-x: 0 !default;
$backlink-desktop-font-size: $backlink-font-size !default;
$backlink-desktop-color: $backlink-color !default;
$backlink-desktop-hover-color: $backlink-hover-color !default;

.backlink {
  padding: $backlink-padding-y $backlink-padding-x;
  text-align: left;

  > * {
    font-size: $backlink-font-size;
    color: $backlink-color;

    &:hover {
      color: $backlink-hover-color;
    }
  }

  @include media-breakpoint-up($backlink-breakpoint) {
    padding: $backlink-desktop-padding-y $backlink-desktop-padding-x;

    > * {
      font-size: $backlink-desktop-font-size;
      color: $backlink-desktop-color;

      &:hover {
        color: $backlink-desktop-hover-color;
      }
    }
  }
}
</style>
