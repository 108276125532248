<template>
  <div :class="['virtual-pager']">
    <div class="seo">
      <slot name="seo" />
    </div>
    <slot name="layout" v-bind="{ visibleItems }">
      <div class="paged-items mb-7">
        <b-row v-bind="{ cols: 1, [`cols-${$root.md.key}`]: 3 }">
          <b-col v-for="(item, index) in visibleItems" :key="index">
            <slot name="item" v-bind="{ item }" />
          </b-col>
        </b-row>
      </div>
    </slot>
    <slot name="buttons" v-bind="{ showMoreButton, showMoreButtonLabel, loadMore }">
      <div class="text-center mt-7" v-if="showMoreButton">
        <b-button variant="primary" @click="loadMore">{{ showMoreButtonLabel }}</b-button>
      </div>
    </slot>
  </div>
</template>

<script>
import MixinAboveTheFold from '@/components/mixins/AboveTheFold'

export default {
  name: 'VirtualPager',
  mixins: [MixinAboveTheFold],
  props: {
    itemsPerPage: {
      type: Number,
      required: true
    },
    showMoreButtonLabel: {
      type: String,
      default: 'Show More'
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      currentPage: 1
    }
  },
  computed: {
    totalItems() {
      return this.items.length
    },
    visibleItems() {
      const totalNumberOfItems = this.currentPage * this.itemsPerPage
      return this.items.slice(0, totalNumberOfItems)
    },
    showMoreButton() {
      return this.totalItems > this.currentPage * this.itemsPerPage
    }
  },
  created() {
    this.initializePageFromUrl()
  },
  mounted() {
    window.addEventListener('popstate', this.initializePageFromUrl)
  },
  beforeDestroy() {
    window.removeEventListener('popstate', this.initializePageFromUrl)
  },
  methods: {
    loadMore() {
      this.currentPage++
      this.updatePageInUrl()
    },
    updatePageInUrl() {
      const newUrl = new URL(window.location)
      // Only add 'page' parameter if currentPage is not 1
      if (this.currentPage !== 1) {
        newUrl.searchParams.set('page', this.currentPage)
      } else {
        newUrl.searchParams.delete('page')
      }
      window.history.pushState({ path: newUrl.toString() }, '', newUrl.toString())
    },
    initializePageFromUrl() {
      const params = new URLSearchParams(window.location.search)
      const pageFromUrl = parseInt(params.get('page'))
      if (!isNaN(pageFromUrl) && pageFromUrl > 1) {
        this.currentPage = pageFromUrl
      } else {
        this.currentPage = 1
        // Remove 'page' parameter if it's 1 or invalid
        const newUrl = new URL(window.location)
        newUrl.searchParams.delete('page')
        window.history.replaceState({ path: newUrl.toString() }, '', newUrl.toString())
      }
    }
  }
}
</script>

<style lang="scss">
</style>
