<template functional>
  <b-button
    v-b-toggle="props.target"
    :id="data.id"
    :class="['accordion-toggle', { 'is-shapeless': props.shapeless, 'left-aligned': props.leftAligned }, data.class, data.staticClass]"
    :style="data.style"
    v-bind="data.attrs"
    variant="link"
    :size="!props.size ? 'md' : props.size"
    role="tab"
    v-if="!props.toggleDisabled"
  >
    <slot /> <lazy-icon v-if="!props.noIcon" class="toggle-icon" :icon="props.icon" font-scale="0.8" />
  </b-button>
  <b-button
    v-else
    :id="data.id"
    variant="link"
    :size="!props.size ? 'md' : props.size"
    role="tab"
    :class="['accordion-toggle', 'accordion-toggle-disabled', { 'is-shapeless': props.shapeless }, data.class, data.staticClass]"
  >
    <slot />
  </b-button>
</template>

<script>
export default {
  name: 'AccordionToggle',
  props: {
    target: {
      type: [String, Number],
      required: true
    },
    size: {
      type: String,
      default: 'md'
    },
    noIcon: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: 'caret-down'
    },
    shapeless: {
      type: Boolean,
      default: false
    },
    toggleDisabled: {
      type: Boolean,
      default: false
    },
    leftAligned: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
$accordion-toggle-border: map-get($borders, 'base') !default;
$accordion-toggle-font-weight: $font-weight-bold !default;
$accordion-toggle-color: inherit !default;
$accordion-toggle-hover-color: inherit !default;
$accordion-toggle-active-color: inherit !default;

$accordion-toggle-icon-transition-in: all cubic-bezier(0.35, 0.05, 0.4, 1.5) 400ms !default;
$accordion-toggle-icon-transition-out: all cubic-bezier(0.35, 0.05, 0.4, 1.5) 200ms !default;

#app {
  .accordion-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 0;
    width: 100%;
    border-bottom: $accordion-toggle-border;
    border-radius: 0;
    box-shadow: none;
    font-weight: $accordion-toggle-font-weight;
    color: $accordion-toggle-color;
    text-align: left;
    text-decoration: none;

    .toggle-icon {
      transition: $accordion-toggle-icon-transition-out;
    }

    &.left-aligned {
      justify-content: flex-start;

      .toggle-icon {
        margin-left: 0.5rem;
      }
    }

    &.accordion-toggle-disabled {
      cursor: default;
    }

    &:hover {
      color: $accordion-toggle-hover-color;
    }

    &[aria-expanded='true'] {
      color: $accordion-toggle-active-color;

      .toggle-icon {
        transform: scaleY(-1);
        transition: $accordion-toggle-icon-transition-in;
      }
    }
  }

  .accordion.is-shapeless .accordion-toggle,
  .accordion-toggle.is-shapeless {
    padding: 0;
    border-bottom: 0;
  }
}
</style>
