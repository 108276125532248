<template>
  <transition name="cookiebanner">
    <div v-if="show" class="cookie-banner">
      <div class="container">
        <slot v-bind="{ close }"/>
      </div>
    </div>
  </transition>
</template>

<script>
import { LOCALE_STORAGE_KEYS } from '@/constants'

const INITIALIZE_TIMEOUT = 1500

export default {
  name: 'CookieBanner',
  data () {
    return {
      show: false
    }
  },
  methods: {
    close () {
      window.localStorage.setItem(LOCALE_STORAGE_KEYS.cookiebanner, false)
      this.show = JSON.parse(window.localStorage.getItem(LOCALE_STORAGE_KEYS.cookiebanner))
    }
  },
  created () {
    setTimeout(() => {
      const storeItem = JSON.parse(window.localStorage.getItem(LOCALE_STORAGE_KEYS.cookiebanner))
      this.show = this.$root.isBoolean(storeItem) ? storeItem : true
    }, INITIALIZE_TIMEOUT)
  }
}
</script>

<style lang="scss">
$cookiebanner-zindex: $zindex-fixed !default;
$cookiebanner-padding-y: $spacer !default;
$cookiebanner-padding-x: 0 !default;
$cookiebanner-bg: rgba($beige, 0.95) !default;
$cookiebanner-boxshadow: map-get($shadows, 'cookiebanner') !default;
$cookiebanner-font-size: $font-size-sm !default;
$cookiebanner-font-weight: inherit !default;
$cookiebanner-color: $dark !default;
$cookiebanner-backdropfilter: blur(5px) !default;
$cookiebanner-transition: transform ease-out 400ms !default;

.cookie-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: $cookiebanner-zindex;
  padding: $cookiebanner-padding-y $cookiebanner-padding-x;
  background-color: $cookiebanner-bg;
  box-shadow: $cookiebanner-boxshadow;
  font-size: $cookiebanner-font-size;
  font-weight: $cookiebanner-font-weight;
  color: $cookiebanner-color;
  backdrop-filter: $cookiebanner-backdropfilter;

  &.cookiebanner-enter,
  &.cookiebanner-leave-to {
    transform: translate3d(0, 100%, 0);
  }

  &.cookiebanner-enter-active,
  &.cookiebanner-leave-active {
    transition: $cookiebanner-transition;
  }

  &.cookiebanner-enter-to,
  &.cookiebanner-leave {
    transform: translate3d(0, 0%, 0);
  }
}
</style>
