<template>
  <div v-if="ready" class="readmore">
    <p class="sr-only" ref="content">
      <slot />
    </p>

    <div aria-hidden="true">
      <div class="readmore-text" v-html="text"></div>
      <a v-if="!content.undershort" class="readmore-toggle" href="#" @click.prevent="toggle">{{ $t(`${tPath}.${toggler.key}`) }} <lazy-icon :icon="toggler.icon" /></a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReadMore',
  props: {
    shortWords: {
      type: Number,
      default: 15
    },
    shortAppend: {
      type: String,
      default: ' ... '
    },
    innerText: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      ready: false,
      content: {
        active: 'short',
        undershort: false,
        short: '',
        long: ''
      }
    }
  },
  computed: {
    text() {
      return this.content.undershort ? this.content.long : { short: this.content.short, long: this.content.long }[this.content.active]
    },
    toggler() {
      return {
        key: { short: 'showmore', long: 'showless' }[this.content.active],
        icon: { short: 'arrow-long-down', long: 'arrow-long-up' }[this.content.active]
      }
    }
  },
  methods: {
    setContent() {
      if (this.innerText === null) {
        this.content.undershort = true
        return
      }

      const text = this.innerText.trim()
      const words = text.replace(/<[^>]*>/g, '').split(' ')

      this.content.long = text
      this.content.short = words
        .slice(0, this.shortWords)
        .concat(this.shortAppend || '')
        .join(' ')
        .trim()

      this.content.undershort = words.length < this.shortWords
    },
    toggle() {
      this.content.active = this.content.active === 'long' ? 'short' : 'long'
    }
  },
  mounted() {
    this.setContent()
    this.ready = true
  }
}
</script>

<style lang="scss">
$readmore-text-gap: $spacer;
$readmore-toggle-font-weight: $font-weight-medium;

.readmore {
  @include clearfix();

  .readmore-text {
    margin-right: $readmore-text-gap;
  }

  .readmore-toggle {
    display: inline-flex;
    flex-wrap: nowrap;
    align-items: center;
    font-weight: $readmore-toggle-font-weight;
  }
}
</style>
