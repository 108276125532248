<template functional>
  <div :id="data.id" :class="['handwriting', data.class, data.staticClass]" :style="data.style" v-bind="data.attrs">
    <p><slot/></p>
  </div>
</template>

<script>
export default {
  name: 'Handwriting'
}
</script>

<style lang="scss">
// mobile
$handwriting-margin: $spacer !default;
$handwriting-font-family: $font-family-handwritten !default;
$handwriting-font-weight: $font-weight-normal !default;
$handwriting-font-size: calc(($font-size-base * 0.6875) + 1.4vw) !default;
$handwriting-line-height: 1.52 !default;
$handwriting-transform: rotate(-4deg) !default;

// desktop
$handwriting-breakpoint: $desktop-breakpoint !default;

$handwriting-desktop-font-size: $font-size-base * 1.5625 !default;

.handwriting {
  margin-bottom: $handwriting-margin;

  p {
    display: inline-block;
    font-family: $handwriting-font-family;
    font-weight: $handwriting-font-weight;
    font-size: $handwriting-font-size;
    line-height: $handwriting-line-height;
    transform: $handwriting-transform;
  }

  &:last-child {
    margin-bottom: 0;

    p {
      margin-bottom: 0;
    }
  }

  @include media-breakpoint-up($handwriting-breakpoint) {
    p {
      font-size: $handwriting-desktop-font-size;
    }
  }
}
</style>
