<template>
  <div
    :class="[
      'contentblock',
      {
        [`bg-pastel-${variant}`]: variant,
        'above-the-fold': onceAboveTheFold,
        narrow: narrow
      }
    ]"
  >
    <c-columns :spotlight="spotlight" :align-v="alignV" :no-gutters="$root.isDesktop && narrow" @hook:mounted="calculateDynamicSlotOrder">
      <template #[dynamicColSlot]>
        <div class="contentblock-text" ref="content-left">
          <slot />
        </div>
      </template>

      <template #col-2>
        <div class="contentblock-text" ref="content-right">
          <slot name="content" />
        </div>
      </template>
    </c-columns>
  </div>
</template>

<script>
import MixinAboveTheFold from '@/components/mixins/AboveTheFold'

export default {
  name: 'ContentBlock',
  mixins: [MixinAboveTheFold],
  props: {
    variant: {
      type: String,
      default: null
    },
    alignV: {
      type: String,
      default: 'center'
    },
    spotlightLeft: {
      type: Boolean,
      default: false
    },
    spotlightRight: {
      type: Boolean,
      default: false
    },
    narrow: {
      type: Boolean,
      default: false
    },
    gtmOptions: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      initialized: false,
      dynamicColSlot: 'col-1',
      gtmProducts: []
    }
  },
  computed: {
    imageOnlySlot() {
      return [
        { el: this.$refs['content-left'], key: 'left' },
        { el: this.$refs['content-right'], key: 'right' }
      ]
        .filter(
          (s) =>
            [].concat(
              Array.from(s.el.querySelectorAll(':scope > img:only-child')),
              Array.from(s.el.querySelectorAll(':scope > :only-child img:only-child'))
            ).length > 0
        )
        .map((s) => s.key)
    },
    spotlight() {
      let spotlight = null

      if (this.spotlightLeft) {
        spotlight = this.dynamicColSlot
      } else if (this.spotlightRight) {
        spotlight = 'col-2'
      }

      return spotlight
    }
  },
  methods: {
    calculateDynamicSlotOrder() {
      this.dynamicColSlot = !this.$root.isDesktop
        ? `col-${this.imageOnlySlot.includes('left') && !this.imageOnlySlot.includes('right') ? 1 : 3}`
        : 'col-1'
    },
    registerGtmProduct(product) {
      this.gtmProducts.push(product)
    },
    gtm() {
      if (!this.initialized) {
        this.initialized = true
        this.$nextTick(() => {
          this.$store.dispatch('gtm/productImpressions', {
            list: this.gtmOptions.list,
            products: this.gtmProducts
          })
        })
      }
    }
  },
  created() {
    this.$root.$on('gtm:registration', this.registerGtmProduct)
  },
  watch: {
    '$root.isDesktop'() {
      this.calculateDynamicSlotOrder()
    },
    gtmProducts() {
      this.gtm()
    }
  }
}
</script>

<style lang="scss">
// mobile
$contentblock-margin-y: $component-padding-y !default;
$contentblock-margin-x: 0 !default;

$contentblock-bg-offset-y: $component-padding-y !default;
$contentblock-bg-offset-x: $component-padding-x !default;

$contentblock-img-transition: opacity ease-out 750ms 250ms, transform ease-out 750ms 250ms !default;

$contentblock-text-align: left !default;

// desktop
$contentblock-breakpoint: $desktop-breakpoint !default;

$contentblock-desktop-margin-y: $component-desktop-padding-y !default;
$contentblock-desktop-margin-x: 0 !default;

$contentblock-desktop-bg-offset-y: $component-desktop-padding-y !default;
$contentblock-desktop-bg-offset-x: $component-desktop-padding-x !default;

$contentblock-desktop-text-align: left !default;

.contentblock {
  position: relative;
  margin: $contentblock-margin-y $contentblock-margin-x;

  .contentblock-text {
    > img:only-child,
    > *:only-child > img {
      display: block;
      margin: 0;
      width: 100%;
      max-width: none;
      height: auto;
      opacity: 0;
      transform: translate3d(0, 5%, 0);
      transition: $contentblock-img-transition;
    }
  }

  .contentblock-text {
    text-align: $contentblock-text-align;
  }

  @each $key, $value in $pastel-colors {
    &.bg-#{$key} {
      background-color: $value;
    }
  }

  &[class*='bg-pastel-'] {
    margin-left: $contentblock-bg-offset-x * -1;
    margin-right: $contentblock-bg-offset-x * -1;
    padding: $contentblock-bg-offset-y $contentblock-bg-offset-x;

    + .contentblock {
      &[class*='bg-pastel-'] {
        margin-top: $contentblock-margin-y * -1;
      }
    }
  }

  &.above-the-fold {
    .contentblock-text {
      > img:only-child,
      > *:only-child > img {
        opacity: 1;
        transform: translate3d(0, 0%, 0);
      }
    }
  }

  &.narrow {
    &[class*='bg-pastel-'] {
      margin-left: 0;
      margin-right: 0;
      padding: 0;

      .row {
        > div {
          margin: 0;
        }
      }

      .contentblock-text {
        padding: $contentblock-bg-offset-y $contentblock-bg-offset-x;
      }
    }
  }

  @include media-breakpoint-up($contentblock-breakpoint) {
    margin: $contentblock-desktop-margin-y $contentblock-desktop-margin-x;

    .contentblock-text {
      text-align: $contentblock-desktop-text-align;
    }

    @each $key, $value in $pastel-colors {
      &.bg-#{$key} {
        margin-left: $contentblock-desktop-bg-offset-x * -1;
        margin-right: $contentblock-desktop-bg-offset-x * -1;
        padding: $contentblock-desktop-bg-offset-y $contentblock-desktop-bg-offset-x;
      }
    }

    &[class*='bg-pastel-'] {
      + .contentblock {
        &[class*='bg-pastel-'] {
          margin-top: $contentblock-desktop-margin-y * -1;
        }
      }
    }

    &.narrow {
      &[class*='bg-pastel-'] {
        margin-left: 0;
        margin-right: 0;
        padding: 0;

        .contentblock-text {
          padding-top: 0;
          padding-bottom: 0;

          > img:only-child,
          > *:only-child > img {
            margin-left: $contentblock-desktop-bg-offset-x * -0.5;
            margin-right: $contentblock-desktop-bg-offset-x * -0.5;
            width: calc(100% + $contentblock-desktop-bg-offset-x);
            max-width: calc(100% + $contentblock-desktop-bg-offset-x);
          }
        }
      }
    }
  }
}
</style>
