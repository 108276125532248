<template functional>
  <a
    :id="data.attrs.id"
    :class="['anker', data.class, data.staticClass]"
    :style="[data.style || {}, { top: `${parent.$root.header.inViewportHeight * -1}px` }]"
    v-bind="data.attrs"
  />
</template>

<script>
export default {
  name: 'Anker'
}
</script>

<style lang="scss">
.anker {
  display: block;
  position: relative;
  visibility: hidden;
}
</style>
