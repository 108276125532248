<template functional>
  <div :id="data.id" :class="['info-container', data.class, data.staticClass]" :style="data.style" v-bind="data.attrs">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'InfoContainer'
}
</script>

<style lang="scss">
$infocontainer-gap: 3px !default;
$infocontainer-padding-y: $spacer * 1.25 !default;
$infocontainer-padding-x: $infocontainer-padding-y !default;
$infocontainer-bg: $beige !default;
$infocontainer-border-radius: $border-radius !default;

$infocontainer-hr-margin-y: $spacer * 0.5 !default;

.info-container  {
  padding: $infocontainer-padding-y $infocontainer-padding-x;
  background-color: $infocontainer-bg;
  border-radius: $infocontainer-border-radius;

  > hr {
    margin-top: $infocontainer-hr-margin-y;
    margin-bottom: $infocontainer-hr-margin-y
    ;
  }

  + .info-container {
    margin-top: $infocontainer-gap;
  }
}
</style>
