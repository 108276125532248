<script>
import debounce from 'lodash/debounce'

export default {
  data () {
    return {
      isLoaded: false,
      aboveTheFold: false,
      onceAboveTheFold: false
    }
  },
  methods: {
    init () {
      this.isLoaded = true
      this.updateAboveThefold.bind(this)()
    },
    updateAboveThefold () {
      if (this.isLoaded && !this.onceAboveTheFold) {
        this.aboveThefold = this.$el.getBoundingClientRect().top < window.innerHeight
        if (!this.onceAboveTheFold) this.onceAboveTheFold = this.aboveThefold
      }
    }
  },
  created () {
    window.addEventListener('load', this.init)
    window.addEventListener('resize', debounce(this.updateAboveThefold, 25))
    window.addEventListener('scroll', debounce(this.updateAboveThefold, 25))
  },
  mounted () {
    this.init()
  },
  beforeDestroy () {
    window.removeEventListener('load', this.init)
    window.removeEventListener('resize', debounce(this.updateAboveThefold, 25))
    window.removeEventListener('scroll', debounce(this.updateAboveThefold, 25))
  }
}
</script>
