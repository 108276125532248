<template>
  <a :href="`#${ankerId}`" :class="['scroll-to-anker']" @click.stop.prevent="scrollToAnker">
    <slot />
  </a>
</template>

<script>
export default {
  name: 'ScrollToAnkerLink',
  props: {
    ankerId: {
      type: String,
      required: true
    }
  },
  methods: {
    scrollToAnker() {
      const anker = document.getElementById(this.ankerId)
      if (anker) {
        anker.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        })
      }
    }
  }
}
</script>

<style lang="scss">
.anker {
  display: block;
  position: relative;
  visibility: hidden;
}
</style>
