<template functional>
  <b-button :id="data.id" :class="['icon-button', data.class, data.staticClass]" :style="data.style" v-bind="data.attrs">
    <span class="btn-text"><slot/></span>
    <lazy-icon class="btn-icon" :icon="props.icon" font-scale="2"/>
  </b-button>
</template>

<script>
export default {
  name: 'IconButton',
  props: {
    icon: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss">
$iconbutton-breakpoint: $desktop-breakpoint !default;

.icon-button {
  @include media-breakpoint-down(get-breakpoint-key($iconbutton-breakpoint, -1)) {
    padding-top: 0;
    padding-bottom: 0;
    background: none!important;
    border: 0 none!important;
    color: inherit!important;

    .btn-text {
      display: none;
    }
  }

  @include media-breakpoint-up($iconbutton-breakpoint) {
    .btn-text {
      display: block;
    }

    .btn-icon {
      display: none;
    }
  }
}
</style>
