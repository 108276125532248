<template>
  <b-collapse
    :id="id"
    :accordion="accordion"
    :visible="visible"
    :class="['accordion-content', { 'is-shapeless': shapeless }]"
    role="tabpanel"
    v-on="$listeners"
  >
    <div>
      <slot/>
    </div>
  </b-collapse>
</template>

<script>
export default {
  name: 'AccordionToggle',
  props: {
    id: {
      type: String,
      required: true
    },
    group: {
      type: String,
      default: null
    },
    visible: {
      type: Boolean,
      default: false
    },
    shapeless: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      accordion: this.group ?? `accordion-${this.$parent._uid}`
    }
  },
  watch: {
    props (newProps) {
      this.props = newProps
    }
  }
}
</script>

<style lang="scss">
$accordion-content-padding-y: $component-padding-y !default;
$accordion-content-padding-x: 0 !default;

.accordion-content {
  > div {
    padding: $accordion-content-padding-y $accordion-content-padding-x;

    > * {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .accordion.is-shapeless &,
  &.is-shapeless {
    border-top: 0 none;

    > div  {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}
</style>
