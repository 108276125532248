<template>
  <div class="row columns" :class="customClasses" v-bind="$attrs" v-on="$listeners">
    <template v-for="colKey in columns">
      <div v-if="$slots[colKey]" :key="colKey" :class="`col-${columnAttributes[colKey].cols}`">
        <slot :name="colKey" />
      </div>
    </template>
  </div>
</template>

<script>
import { COMPONENT_COLUMNS_MAX_COUNT } from '@/constants'

const COLUMN_NAMES = Array.from(new Array(COMPONENT_COLUMNS_MAX_COUNT), (e, i) => `col-${i + 1}`)

export default {
  name: 'Columns',
  props: {
    spotlight: {
      type: String,
      default: 'none'
    },
    alignV: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      maxColumns: this.$root.scss.grid.columns,
      columns: COLUMN_NAMES
    }
  },
  computed: {
    usedSlotsCount() {
      const slotKeys = Object.keys(this.$slots)
      return this.columns.filter((cKey) => slotKeys.includes(cKey)).length
    },
    columnAttributes() {
      return this.columns.reduce((cols, cKey) => {
        const hasSpotlight = this.spotlight === cKey
        const spotlightWidth = Math.min(Math.floor(this.maxColumns / this.usedSlotsCount + 2), this.maxColumns)

        return Object.assign(cols, {
          [cKey]: {
            cols: hasSpotlight ? `${this.$root.md.key}-${spotlightWidth}` : this.$root.md.key
          }
        })
      }, {})
    },
    customClasses() {
      return {
        'align-items-center': this.alignV === 'center',
        'align-items-start': this.alignV === 'top',
        'align-items-end': this.alignV === 'bottom'
      }
    }
  }
}
</script>

<style lang="scss">
// mobile
$columns-col-gap: $spacer !default;

// desktop
$columns-breakpoint: $desktop-breakpoint !default;

.columns {
  > div {
    + div {
      margin-top: $columns-col-gap;
    }
  }

  @include media-breakpoint-up($columns-breakpoint) {
    > div {
      + div {
        margin-top: 0;
      }
    }
  }
}
</style>
