var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'contentblock',
    {
      [`bg-pastel-${_vm.variant}`]: _vm.variant,
      'above-the-fold': _vm.onceAboveTheFold,
      narrow: _vm.narrow
    }
  ]},[_c('c-columns',{attrs:{"spotlight":_vm.spotlight,"align-v":_vm.alignV,"no-gutters":_vm.$root.isDesktop && _vm.narrow},on:{"hook:mounted":_vm.calculateDynamicSlotOrder},scopedSlots:_vm._u([{key:_vm.dynamicColSlot,fn:function(){return [_c('div',{ref:"content-left",staticClass:"contentblock-text"},[_vm._t("default")],2)]},proxy:true},{key:"col-2",fn:function(){return [_c('div',{ref:"content-right",staticClass:"contentblock-text"},[_vm._t("content")],2)]},proxy:true}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }