<template>
  <div :class="['icon-tile-grid', { default: !popularCategories, popularcategories: popularCategories }, `tiles-count-${slotCount}`]">
    <div v-if="$slots.title" class="grid-title">
      <slot name="title" />
    </div>

    <div :class="['row', ...gridCssClasses]">
      <div class="col" v-for="sKey in contentSlotKeys" :key="sKey">
        <slot :name="sKey" />
      </div>
    </div>
  </div>
</template>

<script>
const EXCLUDE_SCOPED_SLOTS = ['title']

const PRODUCTCATEGORIES_COLUMNS_PER_COUNT = {
  1: 1,
  2: 2,
  3: 3,
  4: 2,
  5: 3,
  6: 3,
  7: 4,
  8: 4,
  9: 3,
  10: 4,
  11: 4,
  12: 4
}

export default {
  name: 'IconTileGrid',
  props: {
    popularCategories: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    contentSlotKeys() {
      return Object.keys(this.$scopedSlots || {}).filter((sKey) => !EXCLUDE_SCOPED_SLOTS.includes(sKey))
    },
    slotCount() {
      return Math.max(1, Math.min(12, this.contentSlotKeys.length))
    },
    gridCssClasses() {
      if (this.popularCategories) {
        return [
          'row-cols-2',
          'row-cols-sm-2',
          `row-cols-${this.$root.mt.key}-${PRODUCTCATEGORIES_COLUMNS_PER_COUNT[this.slotCount]}`,
          `row-cols-${this.$root.md.key}-${PRODUCTCATEGORIES_COLUMNS_PER_COUNT[this.slotCount]}`,
          'no-gutters',
          'align-items-center'
        ]
      }

      return [`row-cols-${this.$root.mt.key}-2`, `row-cols-${this.$root.md.key}-2`]
    }
  }
}
</script>

<style lang="scss">
$icontilegrid-title-gap: $spacer !default;

$icontilegrid-popularcategories-border: map-get($borders, 'base') !default;

$icontilegrid-tablet: $tablet-breakpoint !default;

.icon-tile-grid {
  @include component('y');

  .grid-title {
    margin-bottom: $icontilegrid-title-gap;
  }

  &.popularcategories {
    .row {
      .col {
        margin-bottom: -1px;
        margin-right: -1px;
        border: $icontilegrid-popularcategories-border;
      }
    }

    &.tiles-count-1 {
      .row {
        @include media-breakpoint-up($icontilegrid-tablet) {
          max-width: 50%;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
}
</style>
