<template>
  <div>
    <div ref="loadmoreContainer">
        <slot/>
    </div>
    <slot name="button" v-bind="{ loadmore: loadmore ,visible:buttonVisible}"/>
  </div>
</template>

<script>
export default {
  name: 'LoadMore',
  props: {
    itemsPerPage: {
      type: Number,
      default: 10
    }
  },
  data () {
    return {
      visible: false
    }
  },
  computed: {
    buttonVisible () {
      return this.visible
    }
  },
  methods: {
    loadmore () {
      const items = this.$refs.loadmoreContainer.querySelectorAll('.load-more-item.d-none')
      this.visible = items.length > this.itemsPerPage
      for (let i = 1; i <= this.itemsPerPage; i++) {
        if (items.length >= i) {
          items[i - 1].classList.remove('d-none')
        } else {
          return
        }
      }
    }
  },
  mounted () {
    this.loadmore()
  }
}
</script>

<style lang="scss">

</style>
