<template>
  <div :class="['news-teaser', { 'has-link': link !== null }]" @click="delegateClick">
    <div class="zoom-box">
      <aspect-ratio ratio="2x1" :class="['news-teaser-tile', { 'has-icon': iconName }]" :style="{ 'background-image': `url('${img.src}')` }" :enableStyles="onceAboveTheFold">
        <span class="sr-only">{{ img.alt }}</span>
        <lazy-icon class="tile-icon" v-if="iconName" :icon="iconName"/>
      </aspect-ratio>
    </div>

    <div class="news-teaser-content">
      <p class="content-tag" v-if="tag">{{ tag }}</p>
      <slot/>
    </div>
  </div>
</template>

<script>
import MixinAboveTheFold from '@/components/mixins/AboveTheFold'

import AspectRatio from '@/components/private/AspectRatio'

export default {
  name: 'NewsTeaser',
  mixins: [MixinAboveTheFold],
  components: {
    AspectRatio
  },
  props: {
    img: {
      type: Object,
      default: () => ({})
    },
    iconName: {
      type: String,
      default: null
    },
    tag: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      link: null
    }
  },
  methods: {
    registerLink () {
      this.link = this.$el.querySelector('.news-teaser-content [href]') || null
    },
    delegateClick (e) {
      if (!e.target.href && this.link) this.link.dispatchEvent(new MouseEvent('click', { ctrlKey: e.ctrlKey, shiftKey: e.shiftKey, altKey: e.altKey }))
    }
  },
  mounted () {
    this.registerLink()
  }
}
</script>

<style lang="scss">
$newsteaser-gap: $grid-gutter-width !default;

$newsteaser-tile-gap: $paragraph-margin-bottom !default;
$newsteaser-tile-bg: $gray-100 !default;
$newsteaser-tile-icon-size: $spacer * 5 !default;
$newsteaser-tile-icon-color: $primary !default;

$newsteaser-tile-content-tag-gap: $spacer * 0.2 !default;
$newsteaser-tile-content-tag-font-weight: $font-weight-medium !default;
$newsteaser-tile-content-tag-font-size: $font-size-sm !default;
$newsteaser-tile-content-tag-color: $primary !default;

.news-teaser {
  display: block;
  margin-bottom: $newsteaser-gap;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &:last-child {
    margin-bottom: 0;
  }

  .zoom-box {
    margin-bottom: $newsteaser-tile-gap;
    overflow: hidden;
  }

  .news-teaser-tile {
    background: no-repeat center center $newsteaser-tile-bg;
    background-size: cover;
    transition: transform 0.3s ease;

    > * {
      display: flex;
      justify-content: center;
      align-items: center;

      .bi {
        width: $newsteaser-tile-icon-size;
        height: $newsteaser-tile-icon-size;
        color: $newsteaser-tile-icon-color;
      }
    }
  }

  &:hover {
    .news-teaser-tile {
      transform: scale(1.1); // Add this line for zooming
    }
  }

  .news-teaser-content {
    .content-tag {
      margin-bottom: $newsteaser-tile-content-tag-gap;
      font-weight: $newsteaser-tile-content-tag-font-weight;
      font-size: $newsteaser-tile-content-tag-font-size;
      color: $newsteaser-tile-content-tag-color;
    }
  }

  &.has-link {
    cursor: pointer;
  }
}
 </style>
